<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>             
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Base curricular</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Base 
                  <span>curricular</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row mt-4">
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.exibirBasesCurriculares"
                    class="col-12"
                  >                  
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Lista de bases curriculares da plataforma
                            </h6>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                            <button
                              class="btn btn-sm btn-primary"
                              @click.prevent="alteraFastNavegacao('criarBaseCurricular')"
                            >
                              <small>+ Criar base curricular</small>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12 table-responsive mt-2">
                            <table class="table table-sm">
                              <thead class="thead-dark">
                                <tr class="text-center">
                                  <th class="text-center">
                                    <small class="font-weight-bold">Nome</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Status</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Modalidade</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Nível</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ano</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ações</small>
                                  </th>
                                </tr>
                              </thead>
                              <tbody v-if="fastBaseCurricularLoading">
                                <tr>
                                  <td
                                    colspan="6"
                                    class="text-center"
                                    v-html="fastLoadingDados()"
                                  />
                                </tr>
                              </tbody>
                              <tbody v-else-if="fastBaseCurricular.length">
                                <tr
                                  v-for="(e, index) in fastBaseCurricular"
                                  :key="index"
                                >
                                  <td class="align-middle text-center">
                                    <small>{{ e.nome_base_curricular }}</small>                                  
                                  </td>
                                  <td class="align-middle text-center">                                  
                                    <small
                                      v-if="e.status_projeto == 'E'"
                                      class="text-warning"
                                    >Em andamento</small>
                                    <small
                                      v-else-if="e.status_projeto == 'R'"
                                      class="text-warning"
                                    >Em revisão</small>
                                    <small
                                      v-else-if="e.status_projeto == 'F'"
                                      class="text-info"
                                    >Finalizado</small>
                                    <small
                                      v-else-if="e.status_projeto == 'P'"
                                      class="text-success"
                                    >Publicado</small>
                                  </td>
                                  <td class="align-middle text-center">
                                    <div v-if="e.modalidade_ead == 'S'">
                                      <small>EAD</small>
                                    </div>
                                    <div v-if="e.modalidade_presencial == 'S'">
                                      <small>Presencial</small>
                                    </div>
                                    <div v-if="e.modalidade_hibrido == 'S'">
                                      <small>Híbrido</small>
                                    </div>
                                  </td>
                                  <td class="align-middle text-center">
                                    <small>{{ retornaBaseNivel(e.nivel) }}</small>                                  
                                  </td>
                                  <td class="align-middle text-center">
                                    <small>{{ e.ano_faixa }}</small>                                  
                                  </td>                                
                                  <td class="text-center align-middle">       
                                    <button
                                      class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                      @click="exibeModalEditarBaseCurricular(e)"
                                    >
                                      <small>Editar</small>
                                    </button>                           
                                    <a
                                      :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/base-curricular/' + e.id_base_curricular + '/componentes'"
                                      class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                      @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/base-curricular/' + e.id_base_curricular + '/componentes')"
                                    >
                                      <small>Componentes <span class="badge badge-light">{{ e.total_componentes }}</span></small>
                                    </a>
                                    <button
                                      class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                      @click="exibeModalExcluirBaseCurricular(e)"
                                    >
                                      <small>Excluir</small>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td
                                    colspan="6"
                                    class="text-center"
                                  >
                                    Nenhuma base curricular encontrada
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>                  
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.criarBaseCurricular"
                    class="col-12"
                  >                  
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Nova base curricular
                            </h6>
                          </div>                        
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12 mt-4">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Nome da base curricular <small class="text-danger">*</small></label>
                              <input
                                v-model="fastBaseCurricularNovo.nome_base_curricular"
                                type="text"
                                :class="!fastBaseCurricularNovo.nome_base_curricular ? 'form-control border border-danger' : 'form-control'"
                                maxlength="200"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Início da utilização <small class="text-danger">*</small></label>
                              <input
                                v-model="fastBaseCurricularNovo.data_inicio_utilizacao"
                                type="date"
                                :class="!fastBaseCurricularNovo.data_inicio_utilizacao ? 'form-control border border-danger' : 'form-control'"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Fim do conteúdo <small class="text-danger">*</small></label>
                              <input
                                v-model="fastBaseCurricularNovo.data_fim_conteudo"
                                type="date"
                                :class="!fastBaseCurricularNovo.data_fim_conteudo ? 'form-control border border-danger' : 'form-control'"
                              >
                            </div>     
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Status</label>
                              <select
                                v-model="fastBaseCurricularNovo.status_projeto"
                                class="form-control"
                              >
                                <option value="E">
                                  Em andamento
                                </option>
                              </select>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Modalidade EAD</label>
                              <select
                                v-model="fastBaseCurricularNovo.modalidade_ead"
                                class="form-control"
                              >
                                <option value="S">
                                  Sim
                                </option>
                                <option value="N">
                                  Não
                                </option>
                              </select>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Modalidade presencial</label>
                              <select
                                v-model="fastBaseCurricularNovo.modalidade_presencial"
                                class="form-control"
                              >
                                <option value="S">
                                  Sim
                                </option>
                                <option value="N">
                                  Não
                                </option>
                              </select>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Modalidade híbrida</label>
                              <select
                                v-model="fastBaseCurricularNovo.modalidade_hibrido"
                                class="form-control"
                              >
                                <option value="S">
                                  Sim
                                </option>
                                <option value="N">
                                  Não
                                </option>
                              </select>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Nível</label>
                              <select
                                v-model="fastBaseCurricularNovo.nivel"
                                class="form-control"
                              >
                                <option value="1">
                                  Ensino infantil
                                </option>
                                <option value="2">
                                  Ensino fundamental
                                </option>
                                <option value="3">
                                  Ensino médio
                                </option>
                                <option value="4">
                                  EJA
                                </option>
                                <option value="5">
                                  Ensino técnico
                                </option>
                                <option value="6">
                                  Ensino superior
                                </option>
                                <option value="7">
                                  Pós-graduação
                                </option>
                                <option value="8">
                                  Mestrado
                                </option>
                                <option value="9">
                                  Doutorado
                                </option>
                                <option value="10">
                                  Curso livre
                                </option>
                              </select>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Ano <small class="text-danger">*</small></label>
                              <input
                                v-model="fastBaseCurricularNovo.ano_faixa"
                                type="number"
                                maxlength="4"
                                :class="!fastBaseCurricularNovo.ano_faixa ? 'form-control border border-danger' : 'form-control'"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Número sugerido de alunos</label>
                              <input
                                v-model="fastBaseCurricularNovo.nr_sugerido_aluno"
                                type="number"
                                class="form-control"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Carga horária</label>
                              <input
                                v-model="fastBaseCurricularNovo.carga_horaria"
                                type="number"
                                class="form-control"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Eixo tecnológico</label>
                              <input
                                v-model="fastBaseCurricularNovo.eixo_tecnologico"
                                type="text"
                                class="form-control"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Segmento <small class="text-danger">*</small></label>
                              <input
                                v-model="fastBaseCurricularNovo.segmento"
                                type="text"
                                :class="!fastBaseCurricularNovo.segmento ? 'form-control border border-danger' : 'form-control'"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Objetivos <small class="text-danger">*</small></label>
                              <textarea
                                v-model="fastBaseCurricularNovo.objetivos"
                                :class="!fastBaseCurricularNovo.objetivos ? 'form-control border border-danger' : 'form-control'"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Requisitos para acesso</label>
                              <textarea
                                v-model="fastBaseCurricularNovo.requisitos_para_acesso"
                                class="form-control"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Competência principal <small class="text-danger">*</small></label>
                              <textarea
                                v-model="fastBaseCurricularNovo.competencia_principal"
                                :class="!fastBaseCurricularNovo.competencia_principal ? 'form-control border border-danger' : 'form-control'"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Material didático sugerido</label>
                              <textarea
                                v-model="fastBaseCurricularNovo.material_didatico_sugerido"
                                class="form-control"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Conhecimento necessário</label>
                              <textarea
                                v-model="fastBaseCurricularNovo.conhecimento_necessario"
                                class="form-control"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Habilidades</label>
                              <textarea
                                v-model="fastBaseCurricularNovo.habilidade"
                                class="form-control"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Atitude e valores</label>
                              <textarea
                                v-model="fastBaseCurricularNovo.atitude_valores"
                                class="form-control"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Fonte</label>
                              <textarea
                                v-model="fastBaseCurricularNovo.fonte"
                                class="form-control"
                              />
                            </div>              
                            <div class="col-12 mt-4">
                              <div class="form-group mb-0">
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-primary mr-1"
                                  @click="criarBaseCurricular()"
                                >
                                  Criar base curricular
                                </button>
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-secondary mr-1"
                                  @click="alteraFastNavegacao('exibirBasesCurriculares')"
                                >
                                  Cancelar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.alterarBaseCurricular"
                    class="col-12"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Editar base curricular
                            </h6>
                          </div>                        
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Nome da base curricular</label>
                              <input
                                v-model="fastBaseCurricularEditar.nome_base_curricular"
                                type="text"
                                class="form-control"
                                maxlength="200"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Início da utilização</label>
                              <input
                                v-model="fastBaseCurricularEditar.data_inicio_utilizacao"
                                type="date"
                                class="form-control"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Fim do conteúdo</label>
                              <input
                                v-model="fastBaseCurricularEditar.data_fim_conteudo"
                                type="date"
                                class="form-control"
                              >
                            </div>     
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Status</label>
                              <select
                                v-model="fastBaseCurricularEditar.status_projeto"
                                class="form-control"
                              >
                                <option value="E">
                                  Em andamento
                                </option>
                                <option value="R">
                                  Em revisão
                                </option>
                                <option value="F">
                                  Finalizado
                                </option>
                                <option value="P">
                                  Publicado
                                </option>
                              </select>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Modalidade EAD</label>
                              <select
                                v-model="fastBaseCurricularEditar.modalidade_ead"
                                class="form-control"
                              >
                                <option value="S">
                                  Sim
                                </option>
                                <option value="N">
                                  Não
                                </option>
                              </select>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Modalidade presencial</label>
                              <select
                                v-model="fastBaseCurricularEditar.modalidade_presencial"
                                class="form-control"
                              >
                                <option value="S">
                                  Sim
                                </option>
                                <option value="N">
                                  Não
                                </option>
                              </select>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Modalidade híbrida</label>
                              <select
                                v-model="fastBaseCurricularEditar.modalidade_hibrido"
                                class="form-control"
                              >
                                <option value="S">
                                  Sim
                                </option>
                                <option value="N">
                                  Não
                                </option>
                              </select>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Nível</label>
                              <select
                                v-model="fastBaseCurricularEditar.nivel"
                                class="form-control"
                              >
                                <option value="1">
                                  Ensino infantil
                                </option>
                                <option value="2">
                                  Ensino fundamental
                                </option>
                                <option value="3">
                                  Ensino médio
                                </option>
                                <option value="4">
                                  EJA
                                </option>
                                <option value="5">
                                  Ensino técnico
                                </option>
                                <option value="6">
                                  Ensino superior
                                </option>
                                <option value="7">
                                  Pós-graduação
                                </option>
                                <option value="8">
                                  Mestrado
                                </option>
                                <option value="9">
                                  Doutorado
                                </option>
                                <option value="10">
                                  Curso livre
                                </option>
                              </select>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Ano</label>
                              <input
                                v-model="fastBaseCurricularEditar.ano_faixa"
                                type="number"
                                maxlength="4"
                                class="form-control"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
                              <label>Número sugerido de alunos</label>
                              <input
                                v-model="fastBaseCurricularEditar.nr_sugerido_aluno"
                                type="number"
                                class="form-control"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                              <label>Carga horária</label>
                              <input
                                v-model="fastBaseCurricularEditar.carga_horaria"
                                type="number"
                                class="form-control"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Eixo tecnológico</label>
                              <input
                                v-model="fastBaseCurricularEditar.eixo_tecnologico"
                                type="text"
                                class="form-control"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Segmento</label>
                              <input
                                v-model="fastBaseCurricularEditar.segmento"
                                type="text"
                                class="form-control"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Objetivos</label>
                              <textarea
                                v-model="fastBaseCurricularEditar.objetivos"
                                class="form-control"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Requisitos para acesso</label>
                              <textarea
                                v-model="fastBaseCurricularEditar.requisitos_para_acesso"
                                class="form-control"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Competência principal</label>
                              <textarea
                                v-model="fastBaseCurricularEditar.competencia_principal"
                                class="form-control"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Material didático sugerido</label>
                              <textarea
                                v-model="fastBaseCurricularEditar.material_didatico_sugerido"
                                class="form-control"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Conhecimento necessário</label>
                              <textarea
                                v-model="fastBaseCurricularEditar.conhecimento_necessario"
                                class="form-control"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Habilidades</label>
                              <textarea
                                v-model="fastBaseCurricularEditar.habilidade"
                                class="form-control"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Atitude e valores</label>
                              <textarea
                                v-model="fastBaseCurricularEditar.atitude_valores"
                                class="form-control"
                              />
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Fonte</label>
                              <textarea
                                v-model="fastBaseCurricularEditar.fonte"
                                class="form-control"
                              />
                            </div>              
                            <div class="col-12 mt-4">
                              <div class="form-group mb-0">
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-primary mr-1"
                                  @click="editarBaseCurricular()"
                                >
                                  Salvar alterações
                                </button>
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-secondary mr-1"
                                  @click="alteraFastNavegacao('exibirBasesCurriculares')"
                                >
                                  Cancelar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para menu
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarBaseCurricular"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Nova base curricular</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarBaseCurricular')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Nome da base curricular <small class="text-danger">*</small></label>
              <input
                v-model="fastBaseCurricularNovo.nome_base_curricular"
                type="text"
                class="form-control"
                maxlength="200"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Início da utilização <small class="text-danger">*</small></label>
              <input
                v-model="fastBaseCurricularNovo.data_inicio_utilizacao"
                type="date"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Fim do conteúdo <small class="text-danger">*</small></label>
              <input
                v-model="fastBaseCurricularNovo.data_fim_conteudo"
                type="date"
                class="form-control"
              >
            </div>     
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Status</label>
              <select
                v-model="fastBaseCurricularNovo.status_projeto"
                class="form-control"
              >
                <option value="E">
                  Em andamento
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Modalidade EAD</label>
              <select
                v-model="fastBaseCurricularNovo.modalidade_ead"
                class="form-control"
              >
                <option value="S">
                  Sim
                </option>
                <option value="N">
                  Não
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Modalidade presencial</label>
              <select
                v-model="fastBaseCurricularNovo.modalidade_presencial"
                class="form-control"
              >
                <option value="S">
                  Sim
                </option>
                <option value="N">
                  Não
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Modalidade híbrida</label>
              <select
                v-model="fastBaseCurricularNovo.modalidade_hibrido"
                class="form-control"
              >
                <option value="S">
                  Sim
                </option>
                <option value="N">
                  Não
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Nível</label>
              <select
                v-model="fastBaseCurricularNovo.nivel"
                class="form-control"
              >
                <option value="1">
                  Ensino infantil
                </option>
                <option value="2">
                  Ensino fundamental
                </option>
                <option value="3">
                  Ensino médio
                </option>
                <option value="4">
                  EJA
                </option>
                <option value="5">
                  Ensino técnico
                </option>
                <option value="6">
                  Ensino superior
                </option>
                <option value="7">
                  Pós-graduação
                </option>
                <option value="8">
                  Mestrado
                </option>
                <option value="9">
                  Doutorado
                </option>
                <option value="10">
                  Curso livre
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Ano <small class="text-danger">*</small></label>
              <input
                v-model="fastBaseCurricularNovo.ano_faixa"
                type="number"
                maxlength="4"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Número sugerido de alunos</label>
              <input
                v-model="fastBaseCurricularNovo.nr_sugerido_aluno"
                type="number"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Carga horária</label>
              <input
                v-model="fastBaseCurricularNovo.carga_horaria"
                type="number"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Eixo tecnológico</label>
              <input
                v-model="fastBaseCurricularNovo.eixo_tecnologico"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Segmento <small class="text-danger">*</small></label>
              <input
                v-model="fastBaseCurricularNovo.segmento"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Objetivos <small class="text-danger">*</small></label>
              <textarea
                v-model="fastBaseCurricularNovo.objetivos"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Requisitos para acesso</label>
              <textarea
                v-model="fastBaseCurricularNovo.requisitos_para_acesso"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Competência principal <small class="text-danger">*</small></label>
              <textarea
                v-model="fastBaseCurricularNovo.competencia_principal"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Material didático sugerido</label>
              <textarea
                v-model="fastBaseCurricularNovo.material_didatico_sugerido"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Conhecimento necessário</label>
              <textarea
                v-model="fastBaseCurricularNovo.conhecimento_necessario"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Habilidades</label>
              <textarea
                v-model="fastBaseCurricularNovo.habilidade"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Atitude e valores</label>
              <textarea
                v-model="fastBaseCurricularNovo.atitude_valores"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Fonte</label>
              <textarea
                v-model="fastBaseCurricularNovo.fonte"
                class="form-control"
              />
            </div>              
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarBaseCurricular()"
                >
                  Criar curricular
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarBaseCurricular"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar base curricular</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarBaseCurricular')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Nome da base curricular</label>
              <input
                v-model="fastBaseCurricularEditar.nome_base_curricular"
                type="text"
                class="form-control"
                maxlength="200"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Início da utilização</label>
              <input
                v-model="fastBaseCurricularEditar.data_inicio_utilizacao"
                type="date"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Fim do conteúdo</label>
              <input
                v-model="fastBaseCurricularEditar.data_fim_conteudo"
                type="date"
                class="form-control"
              >
            </div>     
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Status</label>
              <select
                v-model="fastBaseCurricularEditar.status_projeto"
                class="form-control"
              >
                <option value="E">
                  Em andamento
                </option>
                <option value="R">
                  Em revisão
                </option>
                <option value="F">
                  Finalizado
                </option>
                <option value="P">
                  Publicado
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Modalidade EAD</label>
              <select
                v-model="fastBaseCurricularEditar.modalidade_ead"
                class="form-control"
              >
                <option value="S">
                  Sim
                </option>
                <option value="N">
                  Não
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Modalidade presencial</label>
              <select
                v-model="fastBaseCurricularEditar.modalidade_presencial"
                class="form-control"
              >
                <option value="S">
                  Sim
                </option>
                <option value="N">
                  Não
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Modalidade híbrida</label>
              <select
                v-model="fastBaseCurricularEditar.modalidade_hibrido"
                class="form-control"
              >
                <option value="S">
                  Sim
                </option>
                <option value="N">
                  Não
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Nível</label>
              <select
                v-model="fastBaseCurricularEditar.nivel"
                class="form-control"
              >
                <option value="1">
                  Ensino infantil
                </option>
                <option value="2">
                  Ensino fundamental
                </option>
                <option value="3">
                  Ensino médio
                </option>
                <option value="4">
                  EJA
                </option>
                <option value="5">
                  Ensino técnico
                </option>
                <option value="6">
                  Ensino superior
                </option>
                <option value="7">
                  Pós-graduação
                </option>
                <option value="8">
                  Mestrado
                </option>
                <option value="9">
                  Doutorado
                </option>
                <option value="10">
                  Curso livre
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Ano</label>
              <input
                v-model="fastBaseCurricularEditar.ano_faixa"
                type="number"
                maxlength="4"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Número sugerido de alunos</label>
              <input
                v-model="fastBaseCurricularEditar.nr_sugerido_aluno"
                type="number"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
              <label>Carga horária</label>
              <input
                v-model="fastBaseCurricularEditar.carga_horaria"
                type="number"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Eixo tecnológico</label>
              <input
                v-model="fastBaseCurricularEditar.eixo_tecnologico"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Segmento</label>
              <input
                v-model="fastBaseCurricularEditar.segmento"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Objetivos</label>
              <textarea
                v-model="fastBaseCurricularEditar.objetivos"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Requisitos para acesso</label>
              <textarea
                v-model="fastBaseCurricularEditar.requisitos_para_acesso"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Competência principal</label>
              <textarea
                v-model="fastBaseCurricularEditar.competencia_principal"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Material didático sugerido</label>
              <textarea
                v-model="fastBaseCurricularEditar.material_didatico_sugerido"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Conhecimento necessário</label>
              <textarea
                v-model="fastBaseCurricularEditar.conhecimento_necessario"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Habilidades</label>
              <textarea
                v-model="fastBaseCurricularEditar.habilidade"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Atitude e valores</label>
              <textarea
                v-model="fastBaseCurricularEditar.atitude_valores"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
              <label>Fonte</label>
              <textarea
                v-model="fastBaseCurricularEditar.fonte"
                class="form-control"
              />
            </div>              
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarBaseCurricular()"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirBaseCurricular"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir base curricular?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirBaseCurricular')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirBaseCurricular()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoSecretariaBaseCurricular",
  components: {},

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      //Navegação entre paginas
      fastNavegacao: {
        exibirBasesCurriculares: true,
        criarBaseCurricular: false,
        alterarBaseCurricular: false,
      },
      fastBaseCurricularLoading: true,
      // Base curricular
      fastBaseCurricular: [],
      fastBaseCurricularNovo: {
        id_base_curricular: 0,
        nome_base_curricular: "",
        data_cadastro: null,
        data_inicio_utilizacao: null,
        data_fim_conteudo: null,
        id_usuario: 0,
        id_professor_tutor: 0,
        id_usuario_revisor: 0,
        status_projeto: "E",
        modalidade_ead: "S",
        modalidade_presencial: "N",
        modalidade_hibrido: "N",
        nivel: "3",
        ano_faixa: 0,
        nr_sugerido_aluno: 40,
        carga_horaria: 0,
        eixo_tecnologico: "",
        segmento: "",
        objetivos: "",
        requisitos_para_acesso: "",
        competencia_principal: "",
        material_didatico_sugerido: "",
        conhecimento_necessario: "",
        habilidade: "",
        atitude_valores: "",
        fonte: "",
        id_plataforma: this.$route.params.id_plataforma              
      },
      fastBaseCurricularEditar: {
        id_base_curricular: "",
        nome_base_curricular: "",
        data_cadastro: "",
        data_inicio_utilizacao: "",
        data_fim_conteudo: "",
        id_usuario: "",
        id_professor_tutor: "",
        id_usuario_revisor: "",
        status_projeto: "",
        modalidade_ead: "",
        modalidade_presencial: "",
        modalidade_hibrido: "",
        nivel: "",
        ano_faixa: "",
        nr_sugerido_aluno: "",
        carga_horaria: "",
        eixo_tecnologico: "",
        segmento: "",
        objetivos: "",
        requisitos_para_acesso: "",
        competencia_principal: "",
        material_didatico_sugerido: "",
        conhecimento_necessario: "",
        habilidade: "",
        atitude_valores: "",
        fonte: "",
        id_plataforma: 0
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            // Recupera base curricular
            this.getBaseCurricular(this.$route.params.id_plataforma);
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    async getBaseCurricular(id_plataforma){
      this.fastBaseCurricularLoading = true
      this.promiseGetFastApi("api/projeto_base_curricular/lista", "id_plataforma=" + id_plataforma + "&id_base_curricular=0").then(obj => {
        if (obj.length) this.fastBaseCurricular = obj
        else    
            this.fastBaseCurricular = [];
        this.$store.state.fastCarregando = false;
        this.fastBaseCurricularLoading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar base curricular", "error");
        this.$store.state.fastCarregando = false;
        this.fastBaseCurricularLoading = false
      })
    },
    async criarBaseCurricular() {        
        let erros = []
        this.fastBaseCurricularNovo.id_professor_tutor = this.retornaSessao(settings.fastSessaoUsuario).Id_Usuario
        this.fastBaseCurricularNovo.id_usuario_revisor = this.retornaSessao(settings.fastSessaoUsuario).Id_Usuario
        
        if (!this.fastBaseCurricularNovo.nome_base_curricular) erros.push("Campo obrigatório: nome da base curricular")
        if (!this.fastBaseCurricularNovo.data_inicio_utilizacao) erros.push("Campo obrigatório: data de inicio da utilização")
        if (!this.fastBaseCurricularNovo.data_fim_conteudo) erros.push("Campo obrigatório: data do fim do conteudo")
        if (!this.fastBaseCurricularNovo.id_professor_tutor) erros.push("Campo obrigatório: professor tutor")
        if (!this.fastBaseCurricularNovo.id_usuario_revisor) erros.push("Campo obrigatório: usuario revisor")
        if (!this.fastBaseCurricularNovo.nivel) erros.push("Campo obrigatório: nível")
        if (!this.fastBaseCurricularNovo.ano_faixa) erros.push("Campo obrigatório: ano")
        if (!this.fastBaseCurricularNovo.nr_sugerido_aluno) erros.push("Campo obrigatório: número de alunos sugeridos")
        if (!this.fastBaseCurricularNovo.segmento) erros.push("Campo obrigatório: segmento")
        if (!this.fastBaseCurricularNovo.objetivos) erros.push("Campo obrigatório: objetivos")
        if (!this.fastBaseCurricularNovo.competencia_principal) erros.push("Campo obrigatório: competência principal")
        if (!this.fastBaseCurricularNovo.id_plataforma) erros.push("Campo obrigatório: id_plataforma")

        if (erros.length) {
            erros.forEach(e => this.exibeToasty(e, "error"))
        } else {
          this.$store.state.fastCarregando = true;
            this.promiseInserirFastApi(this.fastBaseCurricularNovo, "projeto_base_curricular").then((res) => {
                this.exibeToasty("Base curricular criado com sucesso", "success");
                this.alteraFastNavegacao('exibirBasesCurriculares')
                this.fastBaseCurricular = res;
                this.$store.state.fastCarregando = false;
            }).catch((e) => {
                this.exibeToasty("Erro ao criar base curricular", "error");
                this.$store.state.fastCarregando = false;
            });
        }      
    },
    exibeModalEditarBaseCurricular(curricular) {
      if (curricular.data_inicio_utilizacao) curricular.data_inicio_utilizacao = curricular.data_inicio_utilizacao.split("T")[0]
      if (curricular.data_fim_conteudo) curricular.data_fim_conteudo = curricular.data_fim_conteudo.split("T")[0]
      this.fastBaseCurricularEditar = curricular;
      this.alteraFastNavegacao('alterarBaseCurricular')
    },
    async editarBaseCurricular() {
      
      let erros = []

      if (!this.fastBaseCurricularEditar.nome_base_curricular) erros.push("Campo obrigatório: nome da base curricular")
        if (!this.fastBaseCurricularEditar.data_inicio_utilizacao) erros.push("Campo obrigatório: data de inicio da utilização")
        if (!this.fastBaseCurricularEditar.data_fim_conteudo) erros.push("Campo obrigatório: data do fim do conteudo")
        if (!this.fastBaseCurricularEditar.id_professor_tutor) erros.push("Campo obrigatório: professor tutor")
        if (!this.fastBaseCurricularEditar.id_usuario_revisor) erros.push("Campo obrigatório: usuario revisor")
        if (!this.fastBaseCurricularEditar.nivel) erros.push("Campo obrigatório: nível")
        if (!this.fastBaseCurricularEditar.ano_faixa) erros.push("Campo obrigatório: ano")
        if (!this.fastBaseCurricularEditar.nr_sugerido_aluno) erros.push("Campo obrigatório: número de alunos sugeridos")
        if (!this.fastBaseCurricularEditar.segmento) erros.push("Campo obrigatório: segmento")
        if (!this.fastBaseCurricularEditar.objetivos) erros.push("Campo obrigatório: objetivos")
        if (!this.fastBaseCurricularEditar.competencia_principal) erros.push("Campo obrigatório: competência principal")
        if (!this.fastBaseCurricularEditar.id_plataforma) erros.push("Campo obrigatório: id_plataforma")

        if (erros.length) {
            erros.forEach(e => this.exibeToasty(e, "error"))
        } else {
          this.$store.state.fastCarregando = true;
            this.promiseAtualizarFastApi(this.fastBaseCurricularEditar, "projeto_base_curricular").then(e => {
                this.exibeToasty("Salvo com sucesso", "success");                
                this.alteraFastNavegacao('exibirBasesCurriculares')
                this.$store.state.fastCarregando = false;
            }).catch(e => {
                this.exibeToasty("Erro ao salvar alterações", "error");
                this.$store.state.fastCarregando = false;
            });
        }        
    },
    exibeModalExcluirBaseCurricular(curricular) {
      this.fastBaseCurricularEditar = curricular;
      this.showModal("modalExcluirBaseCurricular");
    },
    async excluirBaseCurricular(){
        this.$store.state.fastCarregando = true;
        this.promiseExcluirFastApi(this.fastBaseCurricularEditar, "projeto_base_curricular").then(res => {
            this.exibeToasty("Curricular excluído com sucesso", "success");        
            this.fastBaseCurricular = res;
            this.hideModal("modalExcluirBaseCurricular");
            this.$store.state.fastCarregando = false;
        }).catch(e => {
            this.exibeToasty("Erro ao excluir", "error");
            this.$store.state.fastCarregando = false;
        });
    },
    retornaBaseNivel(nivel) {
      switch (nivel) {
        case 1:
          return 'Ensino infantil'
          break;
        case 2:
          return 'Ensino fundamental'
          break;
        case 3:
          return 'Ensino médio'
          break;
        case 4:
          return 'EJA'
          break;
        case 5:
          return 'Ensino técnico'
          break;        
        case 6:
          return 'Ensino superior'
          break;
        case 7:
          return 'Pós-graduação'
          break;
        case 8:
          return 'Mestrado'
          break;
        case 9:
          return 'Doutorado'
          break;     
        case 10:
          return 'Curso livre'
          break;  
        default:
          break;
      }
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}


@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
